
/**
 * Module definition and dependencies
 */
angular.module('App.Portal.Register.PaymentMethod', [])

/**
 * Config
 */
.config($stateProvider => {
  $stateProvider.state('register.paymentMethod', {
    url: '/payment-method',
    component: 'registerPaymentMethodRoute',
  });
})

/**
 * Route component
 */
.component('registerPaymentMethodRoute', {
  controller: 'RegisterPaymentMethodCtrl',
  templateUrl: 'portal/register/steps/payment-method.html',
  bindings: {
    club: '<',
    transition: '<',
    registration: '<',
    payment: '<',
  },
})

/**
 * Controller
 */
.controller('RegisterPaymentMethodCtrl', function($controller) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('RegisterBaseCtrl', {});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * Setup
   */
  this.setup = function() {

    //Set flags
    this.isLoading = true;

    //Set user
    this.payment.setUser(this.registration.primaryMember);

    //Set line items and clear any errors
    this.payment.clearErrors();
    this.payment.setLineItems(this.registration.lineItems);

    //Load payment providers
    this.payment
      .loadProviders()
      .then(() => this.payment.setupProviders())
      .finally(() => this.isLoading = false);
  };

  this.missingStoredSource = function() {
    return this.payment.method?.isStripe &&
      this.registration.mustStoreSource && !this.payment.isStoringSource;
  };

  /**
   * Continue
   */
  this.confirmPaymentMethod = function() {

    //Error present?
    if (this.payment.hasError) {
      return;
    }

    //Set flag
    this.isConfirming = true;

    //Set as not paying later
    this.registration.setPayingLater(false);

    //Confirm payment method
    this.payment
      .confirmPaymentMethod()
      .then(() => {

        //Only proceed if there's no error
        if (!this.payment.hasError) {
          this.continue();
        }
      })
      .finally(() => this.isConfirming = false);
  };

  /**
   * Pay later
   */
  this.payLater = function() {

    //Set as paying later
    this.registration.setPayingLater(true);
    this.continue();
  };
});
