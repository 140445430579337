
/**
 * Module definition and dependencies
 */
angular.module('Shared.RegistrationFlow.Service', [])

/**
 * Service definition
 */
.factory('RegistrationFlow', function(
  $state, $timeout, $modal, $q, $api, $location, moment, Auth, Member,
  Modules, PaymentLineItems, Config, Helpers, OAuthProviders, PaymentOptions
) {

  //Get constants
  const {AFTER_APPROVAL, FLEXIBLE} = PaymentOptions;

  /**
   * Registration flow class
   */
  class RegistrationFlow {

    /**
     * Constructor
     */
    constructor(club, memberships, payment, customFields) {

      //Flag
      this.hasRegistered = false;

      //Set references
      this.club = club;
      this.memberships = memberships;
      this.payment = payment;
      this.customFields = customFields;
      this.canUseCredentials = club.settings.signIn.canUseCredentials;
      this.canSignUpOthers = club.settings.registration.canSignUpOthers;
      this.isPayingLater = false;

      //Available oAuth providers
      this.providers = OAuthProviders.filter(provider => {
        return club.settings.signIn.providers[provider.id].isEnabled;
      });

      //Pre-selected membership (via link)
      const {membership} = $location.search();
      this.preSelectedMembership = membership;

      //Members data
      this.primaryMember = this.createPrimaryMember();
      this.secondaryMembers = [];

      //Determine steps
      this.determineSteps();

      //Load payment providers
      this.payment.loadProviders();
    }

    /**
     * Array of all members
     */
    get members() {
      return [this.primaryMember].concat(this.secondaryMembers);
    }

    /**
     * Numbered step
     */
    get numberedStep() {
      return this.steps.indexOf(this.step) + 1;
    }

    /**
     * Is at first step
     */
    get isAtFirstStep() {
      return (this.steps.indexOf(this.step) === 0);
    }

    /**
     * Is at last step
     */
    get isAtLastStep() {
      return (this.steps.indexOf(this.step) + 1 === this.steps.length);
    }

    /**
     * Flat array of all subscriptions
     */
    get subscriptions() {
      return this.members.flatMap(member => member.subscriptions);
    }

    /**
     * Flat array of all memberships with conditions
     */
    get membershipsWithConditions() {
      return this.members
        .flatMap(member => member.memberships)
        .filter(membership => !!(membership && membership.conditions))
        .reduce((unique, membership) => {
          const exists = unique.some(m => m.id === membership.id);
          if (!exists) {
            unique.push(membership);
          }
          return unique;
        }, []);
    }

    /**
     * Requires approval flag (strictest)
     */
    get requiresApproval() {

      //Get memberships
      const {club, members} = this;
      const memberships = members
        .flatMap(member => member.memberships);
      if (memberships.length === 0) {
        return club.settings.registration.requiresApproval;
      }

      //Assign values to the flag settings
      const values = {
        always: 3,
        payment: 2,
        none: 1,
      };

      //Check memberships
      return memberships.reduce((strictest, membership) => {
        const {requiresApproval} = membership;
        if (strictest && values[strictest] >= values[requiresApproval]) {
          return strictest;
        }
        return requiresApproval;
      }, null);
    }

    /**
     * Total joining fee
     */
    get totalJoiningFee() {

      //Get data
      const {club, members} = this;
      return members
        .flatMap(member => club.findJoiningFees(member))
        .map(fee => fee ? fee.amount : 0)
        .reduce((total, amount) => total + amount, 0);
    }

    /**
     * Check if we must pay after approval
     */
    get mustPayAfterApproval() {
      return this.subscriptions
        .some(sub => sub.membership.registrationPayment === AFTER_APPROVAL);
    }

    /**
     * Check if we can pay later (only if we have a subscription)
     */
    get canPayLater() {
      if (this.subscriptions.length === 0) {
        return false;
      }
      return this.subscriptions
        .every(sub => sub.membership.registrationPayment === FLEXIBLE);
    }

    /**
     * Total fee payable
     */
    get totalSubscriptionFee() {
      return this.subscriptions.reduce((total, sub) => {
        if (sub.hasFee) {
          return (total + sub.fee.amount);
        }
        return total;
      }, 0);
    }

    /**
     * Has joining fee
     */
    get hasJoiningFee() {
      return (this.totalJoiningFee > 0);
    }

    /**
     * Has subscription fee
     */
    get hasSubscriptionFee() {
      return (this.totalSubscriptionFee > 0);
    }

    /**
     * Has secondary members
     */
    get hasSecondaryMembers() {
      return (this.secondaryMembers.length > 0);
    }

    /**
     * Requires payment flag
     */
    get requiresPayment() {
      if (this.mustPayAfterApproval) {
        return false;
      }
      return (this.hasJoiningFee || this.hasSubscriptionFee);
    }

    /**
     * Must store source flag
     */
    get mustStoreSource() {
      return this.members
        .flatMap(member => member.memberships)
        .some(membership => membership.autoRenewal?.mustStoreSource);
    }

    /**
     * Get payable line items
     */
    get lineItems() {

      //Get data
      const {club, members} = this;
      const lineItems = [];

      //Go over each member
      for (const member of members) {

        //Initialze for member
        const items = [];
        const {subscriptions} = member;
        const fees = club.findJoiningFees(member);

        //Add subs
        for (const sub of subscriptions) {
          PaymentLineItems.addSubMembership(items, sub);
          PaymentLineItems.addSubDiscount(items, sub);
          PaymentLineItems.addSubProRation(items, sub);
        }

        //Add joining fees
        for (const fee of fees) {
          PaymentLineItems.addJoiningFee(items, fee);
        }

        //Add if there was anything
        if (items.length > 0) {
          lineItems.push({member, items});
        }
      }

      //Return
      return lineItems;
    }

    /**
     * Custom fields check
     */
    get hasCustomFields() {
      return (this.customFields.length > 0);
    }

    /**
     * Has membership types
     */
    get hasMembershipTypes() {
      return (Modules.has('memberships') && this.memberships.length > 0);
    }

    /**
     * Has membership conditions
     */
    get hasMembershipConditions() {
      return (this.membershipsWithConditions.length > 0);
    }

    /**
     * Has selected memberships
     */
    get hasSelectedMemberships() {
      return this.members.some(m => m.memberships.length > 0);
    }

    /**************************************************************************
     * Linked members management
     ***/

    /**
     * Add a new secondary member
     */
    addSecondaryMember(member) {
      this.secondaryMembers.push(member);
    }

    /**
     * Remove a secondary member
     */
    removeSecondaryMember(member) {
      const i = this.secondaryMembers.indexOf(member);
      if (i !== -1) {
        this.secondaryMembers.splice(i, 1);
      }
    }

    /**
     * Update household details for linked members in same household
     */
    updateHouseholdDetails() {

      //Get phone and address
      const {phone, address} = this.primaryMember;

      //Loop each secdonary member
      for (const member of this.secondaryMembers) {
        if (member.isSameHousehold) {
          member.phone = phone;
          if (address) {
            member.address = address.clone();
          }
        }
      }
    }

    /**************************************************************************
     * Member management
     ***/

    /**
     * Merge random member data
     */
    mergeRandomMemberData(data) {

      //Random member data
      const firstName = Helpers.pickRandom([
        `Angela`, `Arthur`, `Amy`,
        `Bryan`, `Bob`, `Bill`, `Ben`,
        `Charlotte`, `Claire`, `Chloe`,
        `Dean`, `Dave`, `Damian`, `Emily`, `Gerald`, `Howard`, `Helen`,
        `John`, `Jane`, `Jim`, `Jake`, `Janet`, `Joe`, `Jill`, `Jeremy`,
        `Mary`, `Peter`, `Paul`, `Sam`, `Tess`, `Tobias`, `Vicky`,
      ]);
      const lastName = Helpers.pickRandom([
        `Snow`, `Doe`, `Chu`, `Davis`, `Cook`, `Lee`, `Groznie`, `Mason`,
        `Cole`, `Bennet`, `Cooper`, `Watson`, `Palmer`, `Carr`, `Curtis`,
        `Roberts`, `Harris`, `Evans`, `Russel`, `Ellis`, `Sullivan`,
      ]);
      const mobile = Helpers.pickRandom([
        `022 112 233`,
        `022 121 333`,
        `022 123 313`,
        `022 321 312`,
      ]);

      //Email/username
      const name = `${firstName}${lastName}`.toLowerCase();
      const email = `test+${name}@helloclub.com`;
      const username = email;
      const password = `test123`;

      //Assign data
      Object.assign(data, {
        firstName,
        lastName,
        email,
        mobile,
        username,
        password,
      });
    }

    /**
     * Get default list in directory flag value
     */
    getListInDirectoryDefault(preferredValue) {

      //Module not enabled
      if (!Modules.has('directory')) {
        return false;
      }

      //Preferred flag given (e.g. for secondary member)
      if (typeof preferredValue !== 'undefined') {
        return preferredValue;
      }

      //Check default setting
      return this.club.settings.registration.optInToDirectoryByDefault;
    }

    /**
     * Create a primary member
     */
    createPrimaryMember() {

      //Initialize data
      const data = {
        listInDirectory: this.getListInDirectoryDefault(),
        customFields: {},
        memberships: [],
      };

      //Dev environment, set up test password and username
      if (Config.env === 'dev') {
        this.mergeRandomMemberData(data);
      }

      //Create new member
      return new Member(data);
    }

    /**
     * Create a secondary member
     */
    createSecondaryMember() {

      //Get primary member data
      const {primaryMember} = this;
      const listInDirectory = this
        .getListInDirectoryDefault(primaryMember.listInDirectory);

      //Prepare data
      const data = {
        isSameHousehold: false,
        memberships: [],
        customFields: {},
        listInDirectory,
      };

      //Dev environment, set up test password and username
      if (Config.env === 'dev') {
        this.mergeRandomMemberData(data);
        data.lastName = primaryMember.lastName;
      }

      //Create new member
      return new Member(data);
    }

    /**
     * Apply profile to primary member
     */
    applyProfile(profile) {

      //Get data from profile
      const {id, provider, firstName, lastName, email, gender, photo} = profile;
      const avatar = photo;
      const dob = profile.dob ? moment(profile.dob, 'YYYY-MM-DD') : null;

      //Link profile
      this.primaryMember.linkProfile({provider, id, email});

      //Assign rest of data to primary member
      Object.assign(this.primaryMember, {
        firstName, lastName, email, gender, dob, avatar,
      });
    }

    /**
     * Check member exists
     */
    checkMemberExists() {

      //Get data
      const {club, primaryMember} = this;
      const {firstName, lastName, email} = primaryMember;
      const data = {firstName, lastName, email};

      //Check if member exists by name and email
      return Member
        .exists('nameAndEmail', data)
        .then(exists => {
          if (exists) {
            return $modal
              .open('basic', {
                templateUrl: 'portal/register/modals/member-exists.html',
                locals: {club},
                rejectOnDismissal: true,
              })
              .result;
          }
        });
    }

    /**
     * Find unique username
     */
    findUniqueUsername() {

      //Get data and check if we already have a username
      const {username, firstName, lastName, email} = this.primaryMember;
      if (username) {
        return;
      }

      //Find unique username
      return $api.member
        .uniqueUsername({firstName, lastName, email})
        .then(({username}) => this.primaryMember.username = username);
    }

    /**
     * Make registration data
     */
    makeRegistrationData() {

      //Get JSON of members
      const {primaryMember: primary, secondaryMembers: secondary} = this;

      //Build data
      return {
        primaryMember: this.makeMemberData(primary, true),
        secondaryMembers: secondary.map(member => this.makeMemberData(member)),
      };
    }

    /**
     * Make member registration data
     */
    makeMemberData(member, isPrimary = false) {

      //Extract data
      const {
        firstName, lastName, gender, dob,
        email, phone, mobile, address, postalAddress, customFields,
        username, password, profiles, listInDirectory,
      } = member;

      //Create membership ID's array
      const memberships = member.memberships.map(membership => membership.id);

      //Create base data
      const data = {
        firstName, lastName, gender, dob,
        email, phone, mobile, address, postalAddress, customFields,
        memberships, listInDirectory,
      };

      //Convert DOB
      if (data.dob) {
        data.dob = moment(data.dob).toDob();
      }

      //Append extra for primary member
      if (isPrimary) {
        Object.assign(data, {username, password, profiles});
      }

      //Return data
      return data;
    }

    /**
     * Set paying later status
     */
    setPayingLater(isPayingLater) {
      this.isPayingLater = isPayingLater;
    }

    /**
     * Register
     */
    register() {

      //Already registered?
      if (this.hasRegistered) {
        return $q.resolve();
      }

      //Create data
      const data = this.makeRegistrationData();

      //Missing data? Assuming we navigated back (although this is no longer possible)
      //Can remove this when migrating to Vue
      if (!data.primaryMember || !data.primaryMember.firstName) {
        return $q.resolve();
      }

      //Register
      return $api.user
        .register(data)
        .then(async({user, auth, token}) => {

          //Flag as registered, so we don't try to do it again when a user
          //navigates back in the flow. Store registration token for payment
          //request.
          this.hasRegistered = true;
          this.primaryMember.isPending = user.isPending;
          this.token = token;

          //Log user in if access token was provided
          if (auth) {
            await Auth.loginWithToken(auth.access_token || auth.accessToken);
          }
        });
    }

    /**************************************************************************
     * Step management
     ***/

    /**
     * Set the current step (without going there)
     */
    setStep(step) {
      this.step = step;
    }

    /**
     * Go to a particular step
     */
    goToStep(step) {

      //Already on this step
      if ($state.current.name === `register.${step}`) {
        return;
      }

      //Go to step
      $timeout(() => $state.go(`register.${step}`), 200);
    }

    /**
     * Go to next step
     */
    goToNextStep() {

      //Re-determine steps
      this.determineSteps();

      //Determine next step
      const i = this.steps.indexOf(this.step) + 1;
      if (i >= this.steps.length) {
        return;
      }

      //Get next step
      const step = this.steps[i];
      this.goToStep(step);
    }

    /**
     * Go to previous step
     */
    goToPreviousStep() {

      //Re-determine steps
      this.determineSteps();

      //Determine previous step
      const i = this.steps.indexOf(this.step) - 1;
      if (i < 0) {
        return;
      }

      //Get previous step
      const step = this.steps[i];
      this.goToStep(step);
    }

    /**
     * Finish registration
     */
    finish() {
      this.goToStep('done');
    }

    /**
     * Determine steps
     */
    determineSteps() {

      //Initialize steps
      this.steps = [];

      //Introduction
      this.steps.push('intro');

      //Conditions
      if (this.club.hasTermsAndConditions) {
        this.steps.push('conditions');
      }

      //OAuth
      if (this.providers.length > 0) {
        this.steps.push('oauth');
      }

      //Details
      this.steps.push('details');

      //Secondary members
      if (this.canSignUpOthers) {
        this.steps.push('secondaryMembers');
      }

      //Memberships
      if (this.hasMembershipTypes) {
        this.steps.push('memberships');
        if (this.hasMembershipConditions) {
          this.steps.push('membershipConditions');
        }
      }

      //Custom fields
      if (this.hasCustomFields) {
        this.steps.push('customFields');
      }

      //Login credentials unless registerd with a oAuth profile or disabled
      if (this.canUseCredentials && !this.primaryMember.hasProfiles) {
        this.steps.push('credentials');
      }

      //Payment
      if (this.requiresPayment && this.payment.hasMethods) {
        this.steps.push('paymentMethod');
        if (this.isPayingLater) {
          this.steps.push('confirmation');
        }
        else {
          this.steps.push('paymentSummary');
        }
      }

      //Confirmation (no payment)
      else {
        this.steps.push('confirmation');
      }
    }
  }

  //Return class
  return RegistrationFlow;
});
